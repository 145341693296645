/**
***    monosus からの移植    */

// initialize
//
$(function () {
  pagetop()
  page_anchor()
  tabModule()
  telLink()
  boxContact()
  tab()
});



/** タブ */
const tabModule = function(){
  if( $('.js-tab-module' ).length ){
    $('.js-tab-module').each(function(){
      var $thisTabNav = $(this).find('.js-tab-nav .item a');
      var $thisTabcontent = $(this).find('.js-tab-content');

      $thisTabNav.on('click', function(e){
        e.preventDefault();
        var $this = $(this);
        var $target = $($this.attr('href'));
        if(!$this.hasClass('is-active')){
          $thisTabNav.removeClass('is-active');
          $thisTabcontent.hide();
          $this.addClass('is-active');
          $target.stop().fadeIn(200);
        }
      });

      $(this).find('.item:nth-child(1) a').trigger('click');
    });
  }
}

/** ページ内リンク */
const page_anchor = function(){
  $('.js-anchor').on('click', function(e){

    const href = $(this).attr('href')
    const header = $('header').height()
    let position = $(href).offset().top

    if( href.slice( 0, 1 ) != '#' ){
      e.preventDefault()
    }

    if( ! window.matchMedia( "(min-width: 813px)" ).matches ){
      position = $(href).offset().top - header
    }

    $('body, html').animate({ scrollTop: position }, 400, 'swing')
  })
}


/**
***   kotobuki  */

// pagetop

const pagetop = function () {
  $('.js-pagetop').on('click', function(){
    $('body, html').animate({ scrollTop: 0 }, 400, 'swing')
  })
}



/** telLink */
const telLink = function() {
  const ua = navigator.userAgent;
  if ( ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0 ) {
    $('.js-tel-link').each( function() {
      const html = $(this).html();
      const cls  = $(this).attr('class');
      $(this).replaceWith( '<span class="' + cls + '">' + html + '</span>');
    });
  }
};

/** Box Contact */
const boxContact = function(){
  const fixedContact = $('#js-fixed-contact');
  const toggleClass = 'active';

  if( fixedContact ) {
    fixedContact.on( 'click', function(){
      fixedContact.next().stop().slideToggle();
    });
  }
  // スクロールイベント
  let ticking = false;
  const scrollEventPosition = document.body.offsetHeight;

  //window.addEventListener('scroll', scrollFunc, { passive: true });

  window.onscroll = function () {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if( scrollTop > 160 ){
      fixedContact.next().stop().slideUp(200);
      return;
    } else {
      fixedContact.next().stop().slideDown(200);
      return;
    }
  }
}

/** Tab */
const tab = () => {
  const tabs = document.getElementsByClassName('js-tab');
  const allContents = document.getElementsByClassName('js-contents');
  const currentClassName = 'current';
  const currentViewClassName = 'current-view';
  //
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){

      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };

      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
};
